@import "../Mixins.scss";

.table {
  width: 100%;
  overflow-x: auto;
  &__container {
    min-width: 1100px;
    width: 100%;
    max-width: 100%;
    padding: 10px;
    .table__sticky-column {
      position: sticky;
      background-color: #FFFFFF;
      top: 0;
      left: 0;
      &--active {
        border-right: 1px solid rgba(0, 0, 0, .15);
      }
    }
  }
  &__actions {
    width: 100%;
    min-height: 80px;
  }
  &__header {
    @include flex(space-between);
    width: 100%;
    min-height: 65px;
    height: 65px;
    background-color: #E2E2E2;
    border: 1px solid #C0C0C0;
    box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.15);
  }
  &__header-text {
    @include text(1.4rem, normal, 17px);
    color: #3F3F3F;
    min-width: 125px;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    white-space: pre-line;
  }
  &__content {
    @include flex(center);
    flex-wrap: wrap;
    width: 100%;
    background-color: rgba(241, 241, 241, 0.6);
    
  }
  &__loading {
    @include flex(center);
    min-height: 150px;
  }
  &__row {
    @include flex(space-between);
    width: 100%;
    min-height: 65px;
    padding: 20px 0;
  }
  &__cell {
    @include flex(center);
    @include text(1.2rem, normal, 14px);
    text-transform: none;
    color: #3F3F3F;
    min-width: 125px;
    width: 100%;
    height: 100%;
    margin: 0;
  }
  &__pagination {
    @include flex(flex-end);
    width: 100%;
    min-height: 50px;
    padding: 25px 0;
  }
  &__pagination-texts,
  &__pagination-numbers {
    @include flex(center);
  }
  &__pagination-select,
  &__pagination-text {
    @include text(1.2rem, normal, 16px);
    color: rgba(0, 0, 0, 0.5);
  }
  &__pagination-select {
    margin: 0 10px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
  }
  &__pagination-number {
    @include flex(center);
    @include text(1.4rem, 600, 20px);
    color: rgba(0, 0, 0, 0.5);
    width: 30px;
    height: 30px;
    margin: 0 5px;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    &--disabled,
    &--arrow {
      border: 0;
    }
    &--current {
      width: 32px;
      height: 32px;
      background-color: #F1F1F1;
      color: #000000;
    }
  }

  @include for-size(phone-only) {
    &__pagination {
      flex-direction: column-reverse;
    }
    &__pagination-texts,
    &__pagination-numbers {
      width: 100%;
    }
    &__pagination-numbers {
      margin: 20px 0;
    }
  }
}
