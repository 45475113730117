@import "./Mixins.scss";

/* General styles */
* {
  box-sizing: border-box;
  outline: none;
}

:root {
  --red: #11b8c7;
  --gray: #f5f5f5;
  --black: #414141;
}

html {
  font-size: 10px;
  font-family: "Lato", sans-serif;
}

input,
select,
textarea,
button {
  background-color: transparent;
  border: 0;
}
button {
  cursor: pointer;
  transition: all 0.1s linear;
}
button:active {
  transform: translateY(4px);
}

.loader {
  position: fixed;
  @include flex(center);
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 1000;
  &__spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #ba4231;
      border-color: #ba4231 transparent #ba4231 transparent;
      animation: loader-animation 1.2s linear infinite;
    }
  }
  &__text {
    @include text(2rem, 600, 24px);
    color: #ffffff;
  }
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
