@import "../Mixins.scss";

.platform {
  width: 100%;
  &__sirix {
    @include flex(center);
    flex-direction: column;
    width: 100%;
    background-color: rgba(62, 54, 53, 0.09);
    padding: 50px 70px;
  }
  &__sirix-title {
    @include text(2rem, bold, 24px);
    letter-spacing: 0.25rem;
    color: #3f3f3f;
    text-align: start;
  }
  &__sirix-content {
    @include flex(flex-start);
  }
  &__label {
    position: relative;
    margin: 50px 50px 50px 0;
    &::after {
      @include text(1rem, bold, 1.3rem);
      text-transform: none;
      color: #3f3f3f;
      position: absolute;
      padding: 5px;
      content: attr(data-text);
      background-color: #eeeded;
      top: -25%;
      left: 10px;
    }
  }
  &__input {
    @include text(1.4rem, normal, 17px);
    text-align: start;
    color: #959699;
    min-width: 200px;
    min-height: 40px;
    padding: 5px 10px;
    border: 1px solid #3f3f3f;
    box-sizing: border-box;
    border-radius: 11px;
  }
  &__button {
    @include text(1.2rem, bold, 18px);
    text-decoration: none;
    height: 40px;
    padding: 10px 30px;
    background: var(--red);
    border: 1px solid var(--red);
    box-sizing: border-box;
    box-shadow: 1px 4px 19px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
  }
  &__content {
    padding: 50px 70px 150px;
  }
  &__subtitle {
    @include text(2rem, bold, 24px);
    letter-spacing: 0.25rem;
    text-align: center;
    color: #3f3f3f;
    margin: 30px 0 40px;
  }
  &__download {
    @include grid(1fr 1fr, 30px);
  }
  &__download-box {
    @include flex(center);
    margin-bottom: 100px;
  }
  &__download-text {
    @include text(1.6rem, bold, 22px);
    text-align: start;
    color: #3f3f3f;
    min-width: 320px;
    margin-right: 20px;
  }
  @include for-size(big-desktop) {
    &__download-box {
      margin-bottom: 70px;
    }
  }
  @media screen and (max-width: 1281px) {
    &__download-box {
      flex-direction: column;
    }
    &__download-text {
      text-align: center;
      margin-right: 0;
    }
  }
  @include for-size(desktop) {
    &__sirix,
    &__content {
      padding: 50px 50px 120px;
    }
    &__download-box {
      margin-bottom: 60px;
    }
  }
  @include for-size(tablet-landscape) {
    &__sirix,
    &__content {
      padding: 45px 45px 100px;
    }
    &__download-box {
      margin-bottom: 50px;
    }
  }
  @include for-size(tablet-portrait) {
    &__sirix,
    &__content {
      padding: 35px 30px 100px;
    }
    &__download-box {
      margin-bottom: 40px;
    }
    &__download-text {
      min-width: 240px;
      font-size: 1.4rem;
    }
  }
  @include for-size(phone-only) {
    &__sirix,
    &__content {
      padding: 30px 16px 80px;
    }
    &__download {
      grid-template-columns: 1fr;
    }
    &__download-box {
      width: 100%;
      margin-bottom: 20px;
    }
    &__sirix-content {
      flex-direction: column;
    }
    &__label {
      width: 100%;
      margin: 50px 0;
    }
    &__input {
      width: 100%;
    }
    &__button {
      width: 100%;
    }
  }
}
