@import "../Mixins.scss";

.profile {
  width: 100%;
  &__title {
    @include text(2rem, bold, 24px);
    text-align: start;
    color: #3f3f3f;
  }
  &__description {
    @include text(1.4rem, bold, 17px);
    text-transform: none;
    text-align: start;
    color: #959699;
    margin: 50px 0;
  }
  &__data,
  &__documents {
    width: 100%;
    padding: 50px 70px 150px;
  }
  &__data-title {
    @include flex(space-between);
    align-items: flex-start;
    @include text(1.8rem, bold, 22px);
    text-align: start;
    color: #3f3f3f;
  }
  &__data-title-text {
    @include text(1.4rem, normal, 17px);
    text-transform: none;
    text-align: start;
    color: #959699;
    max-width: 60%;
  }
  &__data-user,
  &__data-password {
    width: 100%;
    margin-bottom: 50px;
    padding: 50px;
    background: #ffffff;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  &__data-form {
    @include flex(space-between);
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 50px;
  }
  &__data-image-container {
    @include flex(center);
    flex-direction: column;
    width: 20%;
    &--modify {
      width: 17%;
      margin: 0 1.5%;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
    }
  }
  &__data-image {
    width: 180px;
    height: 180px;
    margin-bottom: 20px;
    border-radius: 180px;
    &--modify {
      border-radius: 0;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
  &__data-image-text {
    @include text(1rem, bold, 12px);
    color: #959699;
    margin: 20px 0;
  }
  &__data-image-buttons {
    @include flex(space-evenly);
    width: 100%;
  }
  &__data-image-save,
  &__data-image-cancel {
    @include primary-button();
    height: 40px;
    padding: 10px 15px;
    font-size: 1.2rem;
    line-height: 14px;
    border: 1px solid var(--red);
    box-shadow: 1px 4px 19px rgba(0, 0, 0, 0.15);
  }
  &__data-image-cancel {
    border: 1px solid transparent;
    box-shadow: none;
    background-color: transparent;
    color: #000000;
  }
  &__data-image-edit {
    @include text(1.2rem, bold, 14px);
    width: 180px;
    height: 45px;
    background: #3f3f3f;
    box-shadow: 1px 4px 19px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
  }
  &__data-form-grid {
    @include grid(2fr 2fr 3fr, 50px 30px);
    width: 80%;
    &--password {
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__data-form-city {
    width: 100%;
    @include flex(center);
  }
  &__data-form-label {
    position: relative;
    &::after {
      @include text(1rem, bold, 1.3rem);
      text-transform: none;
      color: #3f3f3f;
      position: absolute;
      padding: 5px;
      content: attr(data-text);
      background-color: #ffffff;
      top: -25%;
      left: 10px;
    }
    &--city {
      width: 70%;
      margin-right: 20px;
    }
    &--code {
      width: calc(30% - 20px);
      input {
        min-width: 45px;
      }
    }
    &--direction {
      grid-area: 3 / 1 / 3 / 3;
    }
    &--phone {
      width: 70%;
      display: none;
    }
  }
  &__data-form-input {
    @include text(1.4rem, normal, 17px);
    text-transform: none;
    text-align: start;
    color: #959699;
    min-width: 100px;
    width: 100%;
    min-height: 40px;
    padding: 5px 10px;
    border: 1px solid #3f3f3f;
    box-sizing: border-box;
    border-radius: 11px;
  }
  &__data-form-buttons {
    @include flex(flex-end);
    width: 100%;
    margin-top: 50px;
  }
  &__data-form-button {
    @include primary-button();
    min-width: 200px;
    height: 45px;
    padding: 15px 20px;
    margin-left: 30px;
    font-size: 1.2rem;
    line-height: 14px;
    border: 1px solid var(--red);
    box-shadow: 1px 4px 19px rgba(0, 0, 0, 0.15);
    &--cancel {
      border: 1px solid transparent;
      box-shadow: none;
      background-color: transparent;
      color: #000000;
    }
  }
  &__documents-form-container {
    width: 100%;
    padding: 30px;
    background: #ffffff;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  &__documents-form {
    @include flex(center);
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
  &__documents-form-title {
    @include text(1.4rem, bold, 17px);
    text-align: start;
    color: #3f3f3f;
    margin: 20px 0 35px;
    &--transparent {
      color: transparent;
      &::selection {
        color: transparent;
      }
    }
  }
  &__documents-form-grid {
    @include grid(repeat(2, 1fr), 30px);
    width: 100%;
  }
  &__documents-form-box {
    @include flex(flex-start);
    align-items: flex-start;
    flex-direction: column;
    place-self: center;
  }
  &__documents-form-label {
    position: relative;
    margin: 0 0 100px;
    &::after {
      @include text(1rem, bold, 1.3rem);
      text-transform: none;
      color: #3f3f3f;
      position: absolute;
      padding: 5px;
      content: attr(data-text);
      background-color: #ffffff;
      top: -12px;
      left: 10px;
    }
  }
  &__documents-form-input {
    @include text(1.4rem, normal, 17px);
    text-transform: none;
    text-align: start;
    color: #959699;
    min-width: 200px;
    min-height: 40px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 5px 10px;
    border: 1px solid #3f3f3f;
    box-sizing: border-box;
    border-radius: 11px;
  }
  &__documents-form-upload {
    @include text(1.2rem, normal, 14px);
    height: 42px;
    padding: 10px 30px;
    background: #3f3f3f;
    border: 1px solid #3f3f3f;
    box-sizing: border-box;
    box-shadow: 1px 4px 19px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
  }
  &__documents-form-legend-box {
    @include flex(space-between);
    width: 100%;
    padding: 50px 8%;
  }
  &__documents-form-legend {
    @include text(1.4rem, bold, 17px);
    color: var(--red);
  }
  &__documents-form-submit {
    @include text(1.2rem, bold, 14px);
    min-width: 200px;
    height: 45px;
    padding: 15px 20px;
    background: var(--red);
    border: 1px solid var(--red);
    box-sizing: border-box;
    box-shadow: 1px 4px 19px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
  }
  @include for-size(big-desktop) {
  }
  @include for-size(desktop) {
    &__data,
    &__documents {
      padding: 50px 50px 120px;
    }
    &__data-user,
    &__data-password {
      padding: 40px;
    }
    &__data-title-text {
      max-width: 70%;
    }
    &__data-image-container {
      width: 25%;
      &--modify {
        width: 24%;
        margin: 0 0.5%;
      }
    }
    &__data-image-buttons {
      flex-direction: column;
    }
    &__data-image {
      width: 160px;
      height: 160px;
      border-radius: 160px;
      &--modify {
        border-radius: 0;
      }
    }
    &__data-image-edit {
      width: 160px;
      height: 45px;
    }
    &__data-form-grid {
      width: 75%;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 40px 20px;
      &--password {
        width: 100%;
      }
    }
    &__data-form-label {
      &--city {
        width: 65%;
        margin-right: 15px;
      }
      &--code {
        width: calc(35% - 15px);
      }
      &--phone {
        width: 65%;
      }
    }
    &__documents-form-legend-box {
      padding: 50px 0;
    }
    &__documents-form-legend {
      width: 60%;
    }
  }
  @include for-size(tablet-landscape) {
    &__data,
    &__documents {
      padding: 45px 45px 100px;
    }
    &__data-user,
    &__data-password {
      padding: 30px;
    }
    &__data-title {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }
    &__data-title-text {
      margin-top: 20px;
      max-width: none;
    }
    &__data-image-container {
      width: 50%;
      margin-bottom: 30px;
      &--modify {
        width: 50%;
        margin: 0 0 30px;
      }
    }
    &__data-image {
      width: 140px;
      height: 140px;
      border-radius: 140px;
      &--modify {
        border-radius: 0;
      }
    }
    &__data-image-edit {
      width: 140px;
      height: 45px;
    }
    &__data-form {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &__data-form-grid {
      width: 100%;
      grid-template-columns: 1fr 1fr;
      &--password {
        width: 100%;
      }
    }
    &__data-form-label {
      &--city {
        width: 65%;
        margin-right: 15px;
      }
      &--code {
        width: calc(35% - 15px);
      }
      &--direction {
        grid-area: 4 / 1 / 4 / 3;
      }
      &--phone {
        width: 100%;
      }
    }
    &__documents-form-label {
      margin-bottom: 60px;
    }
    &__documents-form-input {
      width: 100%;
      margin-right: 0;
    }
  }
  @include for-size(tablet-portrait) {
    &__data,
    &__documents {
      padding: 35px 30px 100px;
    }
    &__documents-form-legend-box {
      flex-direction: column;
    }
    &__documents-form-legend {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  @include for-size(phone-only) {
    &__data,
    &__documents {
      padding: 30px 16px 80px;
    }
    &__data-user,
    &__data-password {
      padding: 20px;
    }
    &__data-title {
      margin: 0;
    }
    &__data-image-container {
      width: 70%;
      &--modify {
        width: 70%;
      }
    }
    &__data-form-grid {
      width: 100%;
      grid-template-columns: 1fr;
      &--password {
        width: 100%;
      }
    }
    &__data-form-label {
      &--city {
        width: 60%;
        margin-right: 15px;
      }
      &--code {
        width: calc(40% - 15px);
      }
      &--direction {
        grid-area: auto;
      }
      &--phone {
        width: 100%;
      }
    }
    &__documents-form-label {
      margin-bottom: 0;
    }
    &__documents-form-grid {
      grid-template-columns: 1fr;
    }
  }
}
