@import "../Mixins.scss";
.login{
    @include flex(center);
    background-image: url("../../assets/images/login.png");
    background-position: center;
    background-repeat: no-repeat;
    min-height: 80vh;
    text-align: center;
    &__container{
        max-width: 520px;
        width: 96%;
        padding: 20px;
    }
    &__lang {
        position: fixed;
        top: 30px;
        right: 30px;
        z-index: 10;
    }
    &__logo-container{
        display: block;
        width: 180px;
        margin: 0 auto;
    }
    &__logo{
        width: 100%;
        margin-bottom: 20px;
        margin-top: 50px;
    }
    &__title{
        @include text(2.5rem,bold,3rem);
        letter-spacing: 0.25rem;
    }
    &__item{
        text-align: left;
        display: block;   
        margin-bottom: 10px;
        &--center{
            text-align: center;
        }
        &--password {
            position: relative;
        }
    }
    &__show-password {
        position: absolute;
        padding: 0;
        right: 15px;
        bottom: 32px;
    }
    &__eye {
        width: 22px;
        height: 22px;
    }
    &__form{
        margin: 50px auto;
    }
    &__text{
        @include text(1.4rem,bold,140%);
        text-align: left;
        text-transform: capitalize;
    }
    &__input{
        @include general-input();
        margin-bottom: 20px;
        &[data-error="true"] {
            border: 1px solid #ff2103;
        }
    }
    &__link,&__account{
        @include text(1.4rem,bold,140%);
        text-transform: capitalize;
    }
    &__submit{
        @include primary-button();
        padding: 10px 50px;
        margin: 40px 0 80px;
        font-size: 1.4rem;
    }

}