@import "../Mixins.scss";

.card {
  width: 100%;
  &__card {
    @include flex(flex-end);
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 340px;
    min-height: 190px;
    padding: 20px;
    background: linear-gradient(90deg, #1DBDC7 4.84%, #245A82 100%);
    border-radius: 10px;
    &[data-type="MASTERCARD"] {
      background: linear-gradient(90deg, #B41616 13.18%, #F86754 90.58%);
    }
  }
  &__card-type {
    position: absolute;
    top: 0;
    right: 15px;
    &[data-type="MASTERCARD"] {
      top: 15px;
      right: 15px;
    }
  }
  &__card-holder-name,
  &__card-number {
    @include text(1.6rem, bold, 19px);
    margin: 10px 0 0;
  }
  &__actions {
    @include flex(flex-start);
    width: 100%;
    margin-top: 30px;
  }
  &__button {
    @include flex(center);
    @include text(1rem, bold, 12px);
    color: #3F3F3F;
    min-width: 150px;
    min-height: 35px;
    padding: 0 15px;
    margin-right: 15px;
    background-color: transparent;
    border: 1px solid #3F3F3F;
    box-sizing: border-box;
    border-radius: 20px;
  }
  &__button-icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  @include for-size(tablet-landscape) {
    &__card {
      max-width: 320px;
      min-height: 180px;
    }
  }
  @include for-size(tablet-portrait) {
    &__card {
      max-width: 260px;
      min-height: 160px;
    }
    &__actions {
      flex-direction: column;
      margin-top: 15px;
    }
    &__button {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
  @include for-size(phone-only) {
    &__card {
      width: 280px;
      min-height: 165px;
      margin: 0 auto;
    }
  }
}