@import "../Mixins.scss";

$arrow-icon: url("@icons/arrow_icon.svg");

.header {
  @include grid(1fr 3fr 1fr, 0);
  width: 100%;
  background: linear-gradient(180deg, #000000 0%, #5A5A5A 100%);
  min-height: 120px;
  &__logo {
    display: block;
    place-self: center;
  }
  &__logo-image {
    width: 100%;
    height: 100%;
  }
  &__menu {
    width: 100%;
    place-self: center;
  }
  &__menu-content {
    @include flex(space-evenly);
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__menu-link {
    width: 100%;
    display: block;
    text-decoration: none;
    padding: 10px 0;
    border-bottom: 1px solid transparent;
    &--active {
      border-bottom: 1px solid #FFFFFF;
    }
    &:hover {
      border-bottom: 1px solid #FFFFFF;
    }
  }
  &__menu-text {
    @include text(1.2rem, normal, 14px);
    margin: 0;
  }
  &__user {
    @include flex(flex-start);
    width: 100%;
  }
  &__user-content {
    position: relative;
    @include flex(center);
    flex-wrap: wrap;
    margin: 0 30px;
    &::after {
      position: absolute;
      content: $arrow-icon;
      width: 10px;
      height: 10px;
      top: calc(25% + 5px);
      right: -15px;
      transition: transform .5s ease;
    }
    &[data-show="true"] {
      &::after {
        transform: rotateX(180deg);
      }
    }
  }
  &__user-image {
    width: 45px;
    height: 45px;
    border-radius: 45px;
    border: 2px solid #FFFFFF;
    margin-right: 10px;
  }
  &__user-name {
    @include text(1.2rem, normal, 14px);
    &:hover {
      cursor: pointer;
    }
  }
  &__user-actions {
    @include flex(center);
    flex-direction: column;
    position: absolute;
    width: 100%;
    background-color: #0C0C0C;
    border-radius: 10px;
    bottom: -110px;
    z-index: 10;
  }
  &__user-action {
    @include text(1.2rem, normal, 14px);
    width: 100%;
    min-height: 55px;
    border-radius: 10px;
    &:hover {
      background-color: #757575;
    }
  }
  &__lang {
    padding: 0;
  }
  &__lang-icon {
    margin: 0 10px;
  }
  &__mobile {
    @include flex(space-evenly);
    flex-direction: column;
    position: fixed;
    display: none;
    width: 100%;
    height: 100vh;
    min-height: 400px;
    background: linear-gradient(180deg, #000000 0%, #5A5A5A 100%);
    transition: right 1s ease;
    top: 0;
    right: -100%;
    bottom: 0;
    z-index: 1000;
    &--active {
      right: 0;
    }
  }
  &__button {
    @include text(1.2rem, normal, 14px);
    display: none;
    grid-area: 1 / 3 / 2 / 4;
    &--close {
      position: fixed;
      top: 40px;
      right: -100%;
      transition: right 1s ease;
    }
    &--active {
      right: 40px;
    }
  }
  &__button-icon {
    width: 30px;
    height: 30px;
  }
  @include for-size(desktop) {
    grid-template-columns: .75fr 2.5fr 1fr;
    &__logo {
      width: 100px;
    }
    &__user-content {
      margin: 0 15px;
    }
    &__user-image {
      width: 35px;
      height: 35px;
      border-radius: 35px;
    }
  }
  @include for-size(tablet-landscape) {
    grid-template-columns: .6fr 2.5fr 1fr;
    &__logo {
      width: 80px;
    }
  }
  @include for-size(tablet-portrait) {
    grid-template-columns: 1fr 1fr 1fr;
    &__menu,
    &__user {
      display: none;
    }
    
    &__mobile {
      display: flex;
    }
    &__button {
      display: block;
    }

    &__mobile-logo {
      position: fixed;
      display: block;
      width: 80px;
      top: 40px;
      left: 100%;
      transition: left 1s ease;
      &--active {
        left: 40px;
      }
    }
    &__mobile-logo-image {
      width: 100%;
      height: 100%;
    }
    &__mobile-menu-content {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    &__mobile-menu-link {
      display: block;
      text-decoration: none;
      padding: 10px 0;
      border-bottom: 1px solid transparent;
      &--active {
        border-bottom: 1px solid #FFFFFF;
      }
      &:hover {
        border-bottom: 1px solid #FFFFFF;
      }
    }
    &__mobile-menu-text {
      @include text(1.2rem, normal, 14px);
      margin: 0;
    }
    &__mobile-user {
      @include flex(center);
      flex-direction: column;
    }
    &__mobile-user-content {
      position: relative;
      @include flex(center);
      flex-direction: column;
      &::after {
        position: absolute;
        content: $arrow-icon;
        width: 10px;
        height: 10px;
        top: calc(25% + 5px);
        right: -15px;
        transition: transform .5s ease;
      }
      &[data-show="true"] {
        &::after {
          transform: rotateX(180deg);
        }
      }
    }
    &__mobile-user-image {
      width: 45px;
      height: 45px;
      border-radius: 45px;
      border: 2px solid #FFFFFF;
    }
    &__mobile-user-name {
      @include text(1.2rem, normal, 14px);
      &:hover {
        cursor: pointer;
      }
    }
    &__mobile-user-actions {
      @include flex(center);
      flex-direction: column;
      background-color: #0C0C0C;
      border-radius: 10px;
      bottom: -110px;
      z-index: 10;
    }
    &__mobile-user-action {
      @include text(1.2rem, normal, 14px);
      min-height: 55px;
      border-radius: 10px;
      padding: 0 20px;
      &:hover {
        background-color: #757575;
      }
    }
    &__mobile-lang-icon {
      margin: 10px 0;
    }
  }
  @include for-size(phone-only) {
    min-height: 80px;
    &__logo {
      width: 60px;
    }
    &__mobile-logo {
      top: 18px;
      &--active {
        left: 18px;
      }
    }
    &__button {
      &--close {
        top: 18px;
      }
      &--active {
        right: 18px;
      }
    }
    &__mobile {
      display: flex;
      min-height: 480px;
    }
  }
}