@import "../Mixins.scss";
.forgot{
    @include flex(center);
    background-image: url("../../assets/images/login.png");
    background-position: center;
    background-repeat: no-repeat;
    min-height: 80vh;
    text-align: center;
    &__container{
        max-width: 520px;
        width: 96%;
        padding: 20px;
    }
    &__lang {
        position: fixed;
        top: 30px;
        right: 30px;
        z-index: 10;
    }
    &__logo-container{
        display: block;
        width: 180px;
        margin: 0 auto;
    }
    &__logo{
        width: 100%;
        margin-bottom: 20px;
        margin-top: 50px;
    }
    &__title{
        @include text(2.5rem,bold,3rem);
        letter-spacing: 0.25rem;
    }
    &__item{
        text-align: left;
        display: block;   
        margin-bottom: 10px;
        &--center{
            text-align: center;
        }
    }
    &__form{
        margin: 50px auto;
    }
    &__text{
        @include text(1.4rem,bold,140%);
        text-align: left;
        text-transform: capitalize;
    }
    &__input{
        @include general-input();
        margin-bottom: 20px;
    }
    &__submit{
        @include primary-button();
        padding: 10px 50px;
        margin: 40px 0 80px;
        font-size: 1.4rem;
    }

}
@include for-size(phone-only) {
    .forgot{
        &__submit{
            font-size: 1.4rem;
            margin: 40px 0;
        }
    }
}