@import "../Mixins.scss";
.pure-modal.smallModal {
  width: 80%;
  max-width: 600px;
  border-radius: 20px;
  overflow: auto;
}
.funds {
  width: 100%;
  &__title {
    @include text(2rem, bold, 24px);
    letter-spacing: 0.25rem;
    color: #3f3f3f;
    margin: 30px 0;
  }
  &__subtitle {
    @include text(1.8rem, bold, 22px);
    text-align: start;
    color: #3f3f3f;
    margin: 0;
    margin-bottom: 20px;
  }
  &__deposit,
  &__extract,
  &__deposit-deposit {
    width: 100%;
    padding: 50px 70px 150px;
  }
  &__deposit {
    padding: 50px 70px 30px;
  }
  &__deposit-option {
    @include flex(space-evenly);
    @include text(1.4rem, normal, 17px);
    width: 100%;
    max-width: 380px;
    min-height: 80px;
    background-color: #eeeeee;
    color: #3f3f3f;
    border: 1px solid #e2e2e2;
    place-self: end;
    &:last-of-type {
      place-self: start;
    }
  }
  &__deposit-option-input {
    display: none;
    &:checked + .funds__deposit-option-text::after {
      display: block;
    }
  }
  &__deposit-option-text {
    @include flex(center);
    position: relative;
    margin: 0;
    &::after,
    &::before {
      display: block;
      content: "";
      width: 16px;
      height: 16px;
      margin-right: 15px;
      padding: 2px;
      border: 1px solid #a3a3a3;
      border-radius: 22px;
    }
    &::after {
      position: absolute;
      display: none;
      background-color: var(--red);
      width: 14px;
      height: 14px;
      border: 0;
      top: 2px;
      left: 2px;
      bottom: 2px;
    }
  }
  &__deposit-option-logo {
    max-height: 50px;
    object-fit: contain;
  }
  &__deposit-options,
  &__deposit-amount {
    @include grid(1fr 1fr, 20px);
    width: 100%;
    max-width: 1200px;
    margin: 50px auto 0;
  }
  &__deposit-amount-section {
    @include flex(center);
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    place-self: center;
  }
  &__deposit-card {
    @include flex(center);
    flex-direction: column;
    width: 100%;
    padding: 15px 30px;
    background-color: #ffffff;
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
  &__deposit-card-title {
    @include flex(space-between);
    @include text(1.8rem, bold, 22px);
    width: 100%;
    color: #3f3f3f;
    text-transform: none;
    padding-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;
  }
  &__deposit-card-total {
    @include text(2.4rem, bold, 29px);
    color: #3f3f3f;
  }
  &__deposit-card-submit {
    @include primary-button();
    padding: 15px 40px;
    margin: 30px 0;
    font-size: 1.2rem;
    line-height: 14px;
  }
  &__deposit-card-legend {
    @include text(1.1rem, normal, 13px);
    text-transform: none;
    color: #3f3f3f;
  }
  &__deposit-card-legend-link {
    font-weight: bold;
    color: #3f3f3f;
    text-decoration: none;
  }
  &__account {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__account-content {
    @include flex(space-between);
    width: 100%;
  }
  &__cards {
    @include flex(center);
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 50px 70px;
    background-color: #eeeded;
  }
  &__cards-content {
    @include grid(repeat(auto-fit, minmax(340px, 1fr)), 30px 20px);
    width: 100%;
  }
  &__cards-forms {
    width: 100%;
    padding: 50px 70px;
  }
  &__cards-modify {
    width: 100%;
    margin-top: 50px;
    padding: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  &__cards-form {
    @include flex(center);
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
  }
  &__cards-grid {
    @include grid(auto auto 200px 200px, 30px);
    width: 100%;
  }
  &__cards-actions {
    @include flex(flex-end);
    flex-wrap: wrap;
    width: 100%;
  }
  &__reasons {
    width: 100%;
    padding: 50px;
    background: #ffffff;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  &__reasons-form {
    @include flex(center);
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
  }
  &__grid {
    @include grid(200px 200px auto, 30px);
    width: 100%;
  }
  &__button {
    @include flex(center);
    @include text(1.2rem, bold, 14px);
    text-decoration: none;
    min-width: 180px;
    min-height: 45px;
    padding: 0 15px;
    background-color: var(--red);
    border: 1px solid var(--red);
    box-shadow: 1px 4px 19px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    &--new {
      background-color: #3f3f3f;
      border: 1px solid #3f3f3f;
      margin-left: auto;
    }
    &--cancel {
      color: #3f3f3f;
      background-color: transparent;
      border: 0;
      margin-right: 15px;
      box-shadow: none;
    }
  }
  &__button-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  &__label {
    width: 100%;
    position: relative;
    margin: 40px 0;
    &--margin {
      margin: 20px 0;
    }
    &--nowidth {
      width: auto;
    }
    &::after {
      @include text(1rem, bold, 1.3rem);
      text-transform: none;
      color: #3f3f3f;
      position: absolute;
      padding: 5px;
      content: attr(data-text);
      background-color: #ffffff;
      top: -25%;
      left: 10px;
    }
  }
  &__input {
    @include text(1.4rem, normal, 17px);
    text-align: start;
    color: #959699;
    width: 100%;
    min-width: 200px;
    min-height: 40px;
    padding: 5px 10px;
    border: 1px solid #3f3f3f;
    box-sizing: border-box;
    border-radius: 11px;
    &--nowidth {
      width: auto;
    }
  }
  @include for-size(big-desktop) {
  }
  @include for-size(desktop) {
    &__subtitle,
    &__account {
      margin-bottom: 40px;
    }
    &__reasons {
      padding: 40px;
    }
    &__cards-forms {
      padding: 50px;
    }
    &__deposit-deposit,
    &__extract {
      padding: 50px 50px 120px;
    }
    &__cards {
      padding: 50px;
    }
    &__deposit {
      padding: 50px 50px 30px;
    }
    &__cards-modify {
      padding: 30px;
    }
    &__cards-grid {
      grid-template-columns: repeat(3, 1fr);
    }
    &__grid {
      margin-bottom: 40px;
    }
    &__label {
      margin: 0;
      &--margin {
        margin: 20px 0;
      }
    }
  }
  @include for-size(tablet-landscape) {
    &__cards-forms {
      padding: 45px;
    }
    &__deposit-deposit,
    &__extract {
      padding: 45px 45px 100px;
    }
    &__cards {
      padding: 45px;
    }
    &__cards-content {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
    &__deposit {
      padding: 45px 45px 30px;
    }
    &__cards-grid,
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
    &__cards-grid {
      margin-bottom: 40px;
    }
    &__label {
      &--nowidth {
        width: auto;
      }
      &--margin {
        margin: 20px 0;
      }
    }
    &__input {
      min-width: 180px;
    }
    &__deposit-option {
      max-width: 330px;
    }
    &__deposit-amount-section {
      max-width: 330px;
    }
  }
  @include for-size(tablet-portrait) {
    &__cards-forms {
      padding: 35px 30px;
    }
    &__deposit-deposit,
    &__extract {
      padding: 35px 30px 100px;
    }
    &__cards {
      padding: 35px 30px;
    }
    &__cards-content {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
    &__deposit {
      padding: 35px 30px 30px;
    }
    &__subtitle,
    &__account {
      margin-bottom: 30px;
    }
    &__reasons {
      padding: 30px;
    }
    &__grid {
      grid-template-columns: 1fr;
      margin-bottom: 30px;
    }
    &__deposit-option {
      max-width: 450px;
      margin-bottom: 20px;
      place-self: center;
      &:last-of-type {
        place-self: center;
      }
    }
    &__deposit-options,
    &__deposit-amount {
      grid-template-columns: 1fr;
      margin: 20px auto 0;
    }
    &__deposit-amount-section {
      max-width: 450px;
    }
  }
  @include for-size(phone-only) {
    &__cards-forms {
      padding: 30px 16px;
    }
    &__deposit-deposit,
    &__extract {
      padding: 30px 16px 80px;
    }
    &__cards {
      padding: 30px 16px;
    }
    &__cards-grid,
    &__cards-content {
      grid-template-columns: 1fr;
    }
    &__deposit {
      padding: 30px 16px 20px;
    }
    &__account {
      align-items: center;
      justify-content: center;
    }
    &__account-content {
      justify-content: center;
      flex-wrap: wrap;
      label,
      a {
        margin: 0 20px 20px;
      }
    }
    &__reasons {
      padding: 20px;
    }
    &__cards-actions {
      justify-content: center;
    }
    &__button {
      &--cancel {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    &__deposit-card {
      padding: 15px;
    }
    &__deposit-card-title {
      font-size: 1.6rem;
      line-height: 20px;
    }
    &__deposit-card-total {
      font-size: 2rem;
      line-height: 25px;
    }
  }
  &__modal {
    &-header {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }
    &-link-pago {
      width: 100px;
      padding: 12px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      border-radius: 5%;
      cursor: pointer;
      border: solid 1px var(--red);
      color: var(--red);
      transition: ease 0.2s;
      margin: 10px 0;
      &:hover {
        background-color: var(--red);
        color: #fff;
      }
    }
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    padding: 50px 20px;
  }
}

.psps {
  
  &__url-modal {
    // @include flex(flex-start, 20px, end);
    margin-top: 50px;
    display: flex;
    width: 100%;
    min-width: 280px;
    min-height: 380px;
    padding: 15px 25px;
    background-color: #FFFFFF;
    border: 2px solid #CCCCDA;
    border-radius: 10px;
    flex-direction: column;
    width: 100%;
    height: 600px;
    overflow: hidden;
  }

  &__url-modal-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  &__form-close {
    display: block;
    width: 30px;
    height: 30px;
    align-self: flex-end;
    background-color: #11b8c7;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  &__form-close-icon {
    display: block;
    width: 85%;
    margin: auto;
    object-fit: contain;
  }
  
}