@import "../Mixins.scss";

.home {
  width: 100%;
  &__subtitle {
    @include text(1.8rem, bold, 22px);
    text-align: start;
    color: #3f3f3f;
    width: 100%;
    margin: 40px 0;
  }
  &__tab {
    @include flex(center);
    flex-direction: column;
    padding: 50px 70px 150px;
    &--nopadding {
      padding: 0;
    }
  }
  &__tab-title {
    @include text(2.4rem, normal, 29px);
    color: #3f3f3f;
    margin: 0;
  }
  &__summary-amount {
    width: 100%;
    background-color: rgba(62, 54, 53, 0.09);
    padding: 50px;
  }
  &__summary-table {
    @include flex(space-between);
    width: 80%;
    margin: 50px auto;
    padding: 40px 50px;
    border-radius: 30px;
    background-color: #f9f9f9;
    box-shadow: 0 3px 0 3px var(--red);
  }
  &__summary-table-item {
    @include flex(center);
    flex-direction: column;
  }
  &__summary-table-title {
    @include text(1.4rem, 300, 17px);
    margin: 0 0 20px;
  }
  &__summary-table-price {
    @include text(3rem, bold, 36px);
    margin: 0;
  }
  &__summary-table-title,
  &__summary-table-price {
    color: #3f3f3f;
  }
  &__summary-insert {
    @include text(1.4rem, bold, 17px);
    color: var(--red);
    display: block;
    margin: 80px auto 30px;
    padding: 20px 40px;
    border: 1px solid var(--red);
    border-radius: 30px;
  }
  &__summary-content {
    width: 100%;
    padding: 50px 70px 150px;
  }
  &__activity-form-label,
  &__activity-label {
    position: relative;
    margin: 50px auto 50px 0;
    &::after {
      @include text(1rem, bold, 1.3rem);
      text-transform: none;
      color: #3f3f3f;
      position: absolute;
      padding: 5px;
      content: attr(data-text);
      background-color: #ffffff;
      top: -25%;
      left: 10px;
    }
  }
  &__activity-form-input,
  &__activity-input {
    @include text(1.4rem, normal, 17px);
    text-align: start;
    color: #959699;
    min-width: 200px;
    min-height: 40px;
    padding: 5px 10px;
    border: 1px solid #3f3f3f;
    box-sizing: border-box;
    border-radius: 11px;
  }
  &__activity-form {
    @include flex(space-between);
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 50px;
  }
  &__activity-form-label {
    margin: 20px 0;
    &--checkbox {
      @include flex(center);
    }
  }
  &__activity-form-input {
    margin: 0;
    &--checkbox {
      min-width: 30px;
      width: 30px;
      min-height: 30px;
      height: 30px;
      margin-right: 20px;
    }
  }
  &__activity-form-text {
    @include text(1.2rem, bold, 14px);
    text-align: start;
    color: #3f3f3f;
    width: 120px;
  }
  &__activity-form-submit {
    @include text(1.2rem, bold, 14px);
    min-width: 125px;
    padding: 20px 30px;
    background: #3f3f3f;
    box-shadow: 1px 4px 19px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
  }
  @include for-size(big-desktop) {
  }
  @include for-size(desktop) {
    &__summary-amount,
    &__summary-content,
    &__tab {
      padding: 50px 50px 120px;
      &--nopadding {
        padding: 0;
      }
    }
  }
  @include for-size(tablet-landscape) {
    &__summary-amount,
    &__summary-content,
    &__tab {
      padding: 45px 45px 100px;
      &--nopadding {
        padding: 0;
      }
    }
    &__summary-table {
      width: 90%;
      padding: 40px;
    }
    &__activity-form {
      justify-content: flex-start;
    }
    &__activity-form-label {
      margin-right: 20px;
    }
  }
  @include for-size(tablet-portrait) {
    &__summary-amount,
    &__summary-content,
    &__tab {
      padding: 35px 30px 100px;
      &--nopadding {
        padding: 0;
      }
    }
    &__summary-table {
      width: 95%;
      padding: 30px 20px;
    }
    &__subtitle {
      font-size: 1.6rem;
    }
    &__summary-amount,
    &__summary-content,
    &__tab-title {
      font-size: 2rem;
    }
    &__activity-form-label {
      margin-right: 15px;
    }
  }
  @include for-size(phone-only) {
    &__summary-amount,
    &__summary-content,
    &__tab {
      padding: 30px 16px 80px;
      &--nopadding {
        padding: 0;
      }
    }
    &__summary-table {
      flex-direction: column;
      width: 98%;
    }
    &__summary-table-title {
      margin-bottom: 15px;
    }
    &__summary-table-item {
      margin-bottom: 25px;
    }
    &__activity-form-label,
    &__activity-label {
      width: 100%;
      margin: 30px auto;
    }
    &__activity-form-input,
    &__activity-input {
      width: 100%;
    }
    &__activity-form-input {
      &--checkbox {
        min-width: 30px;
        width: 30px;
        min-height: 30px;
        height: 30px;
      }
    }
    &__activity-form-label {
      &--checkbox {
        justify-content: flex-start;
      }
    }
    &__activity-form-submit {
      width: 100%;
    }
  }
}

.actions {
  &__buttons {
    @include flex(space-evenly);
    width: 100%;
  }
  &__button {
    width: 40px;
    height: 40px;
    padding: 0;
    background: #3f3f3f;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
  }
  &__item {
    text-align: center;
  }
  &__icon {
    width: 20px;
  }
}
