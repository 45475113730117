@import "../Mixins.scss";

.language {
  @include flex(center);
  @include text(1.4rem, normal, 20px);
  position: relative;
  color: #FFFFFF;
  text-transform: uppercase;
  min-width: 50px;
  margin: 0;
  padding: 5px 0;
  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  &:hover {
    cursor: pointer;
    .language__menu {
      height: 80px;
      min-height: 80px;
      padding: 10px 0;
    }
    .language__menu-button {
      display: block;
    }
  }
  &__menu {
    @include flex(space-around);
    flex-direction: column;
    position: absolute;
    min-width: 100%;
    height: 0;
    min-height: 0;
    padding: 0;
    background-color: #FFFFFF;
    top: 100%;
    z-index: 100;
    transition: .75s ease;
    transition-property: height, min-height, padding;
  }
  &__menu-button {
    display: none;
    width: 100%;
    padding: 5px;
    color: var(--black);
    transition: background-color .5s, color .3s;
    transition-timing-function:  ease;
    &:active,
    &:hover {
      background-color: var(--red);
      color: #FFFFFF;
    }
    &[disabled],
    &[disabled]:active,
    &[disabled]:hover {
      background-color: transparent;
      color: lightgray;
    }
  }
}