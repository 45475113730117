@import "../Mixins.scss";

.support {
  width: 100%;
  &__title {
    @include text(2.4rem, normal, 29px);
    letter-spacing: 0.25rem;
    color: #3F3F3F;
    margin: 0;
  }
  &__content {
    width: 100%;
    padding: 50px 70px 150px;
  }
  &__subtitle {
    @include text(1.8rem, bold, 22px);
    text-align: start;
    color: #3F3F3F;
    width: 100%;
    margin: 40px 0;
  }
  @include for-size(big-desktop) {
  
  }
  @include for-size(desktop) {
    &__content {
      padding: 50px 50px 120px;
    }
  }
  @include for-size(tablet-landscape) {
    &__content {
      padding: 45px 45px 100px;
    }
  }
  @include for-size(tablet-portrait) {
    &__content {
      padding: 35px 30px 100px;
    }
  }
  @include for-size(phone-only) {
    &__content {
      padding: 30px 16px 80px;
    }
  }
}