@import "../Mixins.scss";

.footer {
  background: linear-gradient(180deg, #000000 0%, #5a5a5a 100%);
  color: white;
  padding: 60px 0 20px;
  &__container {
    @include fixed-container();
    @include grid(3fr 1fr, 60px);
  }
  &__logo {
    width: 120px;
    margin-bottom: 30px;
  }
  &__text {
    white-space: pre-line;
  }
  &__list {
    display: inline-block;
    list-style: none;
    vertical-align: top;
    margin: 0 40px;
    padding: 0;
  }
  &__item {
    margin-bottom: 40px;
  }
  &__link {
    @include text(1.1rem, 900, 1.4rem);
    display: block;
    color: white;
    text-align: start;
    text-decoration: none;
    text-transform: uppercase;
    &--small {
      font-weight: 400;
      font-size: 1.2rem;
      margin: 15px 0 0;
    }
  }
  @include for-size(tablet-landscape) {
    &__container {
      grid-template-columns: 1fr;
    }
    &__list {
      @include flex(center);
      align-items: flex-start;
      flex-wrap: wrap;
      margin: auto;
    }
    &__item {
      display: inline-block;
      margin: 10px 20px;
    }
  }
  @include for-size(phone-only) {
    &__list {
      flex-direction: column;
    }
  }
}