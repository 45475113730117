@import "../Mixins.scss";

$hero-bg: url("../../assets/images/hero_bg.png");

.hero {
  @include flex(center);
  position: relative;
  width: 100%;
  min-height: 260px;
  background-image: $hero-bg;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &__title {
    @include text(3.6rem, normal, 43px);
    letter-spacing: 0.75rem;
  }
  &__tabs {
    @include flex(center);
    position: absolute;
    padding: 10px 0;
    bottom: 10px;
  }
  &__tab {
    @include text(1.5rem, bold, 18px);
    letter-spacing: 0.25rem;
    display: block;
    padding: 0 60px;
    border-right: 1px solid #ffffff;
    &:last-of-type {
      border-right: 1px solid transparent;
    }
    &--active {
      position: relative;
      &::after {
        position: absolute;
        width: calc(100% - 120px);
        height: 18px;
        content: "";
        background-color: var(--red);
        bottom: -30px;
        left: 60px;
        right: 60px;
      }
    }
  }
  @include for-size(big-desktop) {
  }
  @include for-size(desktop) {
  }
  @include for-size(tablet-landscape) {
  }
  @include for-size(tablet-portrait) {
    &__title {
      font-size: 3rem;
    }
    &__tab {
      padding: 0 30px;
      &--active {
        &::after {
          width: calc(100% - 60px);
          left: 30px;
          right: 30px;
        }
      }
    }
  }
  @include for-size(phone-only) {
    min-height: 200px;
    &__title {
      font-size: 2.6rem;
    }
    &__tab {
      padding: 0 10px;
      font-size: 1rem;
      &--active {
        &::after {
          width: calc(100% - 20px);
          left: 10px;
          right: 10px;
        }
      }
    }
  }
}
