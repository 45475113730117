@import "../Mixins.scss";

.register{
    @include flex(center);
    background-image: url("@images/login.png");
    background-position: center;
    background-repeat: no-repeat;
    min-height: 80vh;
    text-align: center;
    &__container{
        max-width: 1000px;
        width: 96%;
        padding: 20px;
    }
    &__lang {
        position: fixed;
        top: 30px;
        right: 30px;
        z-index: 10;
    }
    &__head{
        @include flex(space-between);
        border-bottom: 1px solid white;
        padding-bottom: 20px;
        margin-top: 50px;
    }
    &__logo-container{
        display: block;
        width: 140px;
    }
    &__logo{
        width: 100%;       
    }
    &__title{
        @include text(2.5rem,bold,3rem);
        letter-spacing: 0.25rem;
    }
    &__item{
        text-align: left;
        display: block;   
        position: relative;
        &--double{
            text-align: center;
            grid-column: span 2;
        }
        
    }
    &__phone{
        @include flex(center);
    }
    &__form{
        @include grid(1fr 1fr, 30px);
        margin: 50px auto;
    }
    &__text{
        @include text(1.4rem,bold,140%);
        text-align: left;
        text-transform: capitalize;
    }
    &__input{
        @include general-input();
        &--code{
            width: 25%;
            margin-right: 20px;
            padding: 15px 10px;
            &::placeholder {
                color: white;
            }
        }
        &--error {
            border: 1px solid #ff2103;
        }
    }
    &__link,&__account{
        @include text(1.4rem,bold,140%);
        text-transform: capitalize;
    }
    &__submit{
        @include primary-button();
        padding: 10px 50px;
        margin: 40px 0 80px;
        font-size: 1.4rem;
    }
    &__legend {
        @include text(1.2rem, normal, 140%);
        display: block;
        text-transform: none;
        margin: 10px 0;
    }
    &__show-password{
        position: absolute;
        top: 58px;
        right: 15px;
    }
    &__country-options{
        color: black;
    }
}
@include for-size(phone-only) {
    .register{
        &__logo{
            width: 100px;
        }
    &__title{
        font-size: 2rem;
    }
    &__form{
        grid-template-columns: 1fr;
    }
        &__item{
     
            &--double{
                grid-column: span 1;
            }
        }
    }
    &__submit{
        @include primary-button();
        padding: 10px 20px;
    }
}