@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait {
    @media (max-width: 750px) {
      @content;
    }
  } @else if $size == tablet-landscape {
    @media (max-width: 900px) {
      @content;
    }
  } @else if $size == desktop {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop {
    @media (max-width: 1800px) {
      @content;
    }
  }
}
@mixin flex($justify) {
  display: flex;
  justify-content: $justify;
  align-items: center;
}
@mixin grid($columns, $gap) {
  display: grid;
  grid-template-columns: ($columns);
  grid-gap: ($gap);
}
@mixin text($size, $weight, $line-height) {
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
@mixin general-input() {
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  padding: 15px 20px;
  width: 100%;
  color: white;
  font-size: 1.5rem;
  max-height: 49px;
}
@mixin primary-button {
  @include text(1.8rem, bold, 22px);
  background: var(--red);
  border-radius: 30px;
}
@mixin fixed-container() {
  max-width: 1440px;
  margin: auto;
  width: 92%;
}
